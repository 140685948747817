/*
 *   File : footer.module.scss
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : More Button Style
 *   Integrations :
 *   Version : v1.0
 */

@import "./../config.scss";

.e_footer {
  background: $footer_bg;
  padding: 50px;
  position: relative;
  z-index: 10;
  // height: 130px;
  .e_footer_logo {
    transition: ease-in-out 0.5s;
    &:hover {
      transform: scale((0.8));
      cursor: pointer;
    }
  }
  .e_footer_left {
    font-weight: 400;
    @extend %font14;
    line-height: 160%;
    color: $input_boder;
    padding-left: 16px;
  }
  .e_social_wrap {
    .e_link a {
      font-weight: 400;
      @extend %font14;
      line-height: 160%;
      color: $grey;
      text-decoration: none !important;
      // margin-right: 48px;
      &:hover {
        color: $primary_color;
        text-decoration: underline;
        transition: 0.3s ease-in-out;
      }
    }
  }
  .e_footer_company_link {
    color: $primary_color;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
      transition: 0.3s ease-in-out;
    }
  }
  .e_footer_label {
    color: $input_boder;
    @extend %font12;
    font-weight: 400;
  }
  .e_footer_powered {
    color: $primary_color;
    @extend %font12;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
      transition: 0.3s ease-in-out;
    }
  }
}
