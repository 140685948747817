/*
 *   File : outerHeader.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Header
 *   Integrations : null
 *   Version : 1.0
 *   Created : 10-11-2021
 */

@import "./../config.scss";

.e_outer_header {
  padding: 35px 52px;
  background-color: $secondary_color;
  .e_logo {
    // width: 109.43px;
    height: 32px;
    transition: ease-in-out 0.5s;
    &:hover {
      transform: scale((0.8));
      cursor: pointer;
    }
  }
  .e_btn {
    color: $white;
    &:hover {
      cursor: pointer;
      transition: 0.3s ease-in-out;
    }
  }
}
