/*
 *   File : acccordion.module.scss
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : FAQ Accordion style
 *   Integrations :
 *   Version : v1.0
 */

@import "./../config.scss";

.e_game_accordion {
  .e_accordion_button {
    display: block;
    border: none !important;
    background-color: $white;
    text-align: left;
    width: 100%;
    position: relative;
    border-radius: 0;
    outline: none;
    &[aria-expanded="false"] {
      .e_accordion_icon {
        position: absolute;
        top: 35%;
        right: 0px;
        background-image: url(../../assets/images/dataFormating/add.svg);
        background-repeat: no-repeat;
        padding: 13px;
        transition: 0.5s ease-in-out;
      }
    }
    &[aria-expanded="true"] {
      .e_accordion_icon {
        // position: absolute;
        // top: 35%;
        // right: 0px;
        background-image: url(../../assets/images/dataFormating/minus.svg);
        // background-repeat: no-repeat;
        // padding: 13px;
        // transition: 0.5s ease-in-out ;
      }
    }
  }
  .e_accordion_item {
    border: none !important;
    padding: 10px 0px !important;
  }
  .e_accordion_title {
    font-weight: 400;
    @extend %font14;
    line-height: 20px;
    color: $black;
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
  .e_accordion_wrapper {
    background: $accordion_bg;
    border-radius: 8px;
    padding: 10px;
    .e_accordion_content {
      font-weight: 400;
      @extend %font14;
      line-height: 150%;
      color: $light_grey;
    }
  }
  .e_delete {
    color: $delete;
    &:hover {
      opacity: 0.7;
    }
  }
  .e_edit {
    &:hover {
      opacity: 0.7;
    }
  }
}
.e_accordion_body {
  padding: 0 0 !important;
}
