@import "./../config.scss";

.e_card_wrapper {
  border-radius: 8px;
  border: 1px solid #ccc;
  min-height: 248px;
  .e_page_name {
    background-color: #f3f6fc;
    border-radius: 8px;
    color: #1f1136;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 120%;
    padding: 10px;
    display: inline-block;
    img {
      width: 13.42px;
      height: 12.22px;
    }
  }
}

.e_card_wrap {
  border-radius: 8px;
  border: 1px solid #ccc;
  .e_page_name {
    background-color: #f3f6fc;
    border-radius: 8px;
    color: #1f1136;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 120%;
    padding: 16px;
    display: inline-block;
    margin-bottom: 16px;
    img {
      width: 16px;
      height: 16px;
      margin-top: 4px;
      margin-left: 16px;
    }
  }
}
