/*
* @Author: Evouqe Innovative Lab
* @Date:   2021-11-12 17:35:19
* @Last Modified time: 2021-11-12 18:32:23
*/

@import "./../config.scss";

.e_fund_card{
	background-color: $white;
	border-radius: 8px;
	box-shadow: 8px 16px 56px rgba(0, 0, 0, 0.04);
	height: 230px;
	.e_card_title{
		@extend %h6;
	}
	.e_low_risk{
		font-weight: 400;
		line-height: 15px;	
		@extend %font12;
		color: $green;
	}
	.e_medium_risk{
		@extend .e_low_risk;
		color: $orange;
	}
	.e_high_risk{
		@extend .e_low_risk;
		color: $red;
	}
	.e_p_btn{
		@extend %font14;
	}

	.e_inner_card{
		background-color: $off_white;
		border-radius: 8px;
		height: 138px;
		.e_logo{
			width: 32px;
			height: 32px;
			object-fit: cover;
		}
		.e_inner_card_title{
			color: $dark_grey;
			@extend  %font14;
			line-height: 150%;
			font-weight: 600;
		}
		label{
			color: $light_grey;
		}
		.e_returns{
			color: $dark_grey;
			@extend %font12;
			font-weight: 700;
			span{
				font-weight: 400!important;
			}
		}
	}
}