/*-- Import supporting css*/
@import "./config.scss";
@import "./components/selectBox.module.scss";
@import "./components/dataTable.module.scss";
@import "./components/pie.module.scss";
@import "./components/bar.module.scss";
@import "./components/dateRangepicker.module.scss";
@import "./components/area.module.scss";
@import "./components/singleDate.module.scss";
@import "./components/editor.module.scss";
@import "./components/timePicker.module.scss";

/*---------- Body ---------- */
body {
    font-family: $font-primary;
    background-color: #f3f3f3;
    -webkit-font-smoothing: antialiased;

    &::-webkit-scrollbar {
        display: none !important;
        width: 0px;
    }

    &::-webkit-scrollbar-thumb {
        display: none !important;
        width: 0px;
    }
}

::-webkit-scrollbar {
    width: 0px;
}

/*------- App layout --------*/
.e-content-wrap {
    min-height: 100vh;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
}

.e-sidebar-wrap {
    // transition: ease-in-out 0.5s;
    position: relative;
    height: 100vh;
    width: 307px;
}

.e-sidebar-collapsed {
    // transition: ease-in-out 0.1s;
    width: 50px;
}

.e-content-body {
    margin-top: 10%;
    background-color: #f6f9ff;
}

/*----auth--------*/
.e-auth-wrapper {
    background-color: $secondary_color;
    overflow-x: hidden;

    .e-left-banner {
        position: relative;
        top: 90px;
    }

    .e-right-banner {
        position: relative;
        right: -70px;
        top: 330px;
    }

    .e-form-wrap {
        background: $white;
        box-shadow: 8px 16px 56px rgba(0, 0, 0, 0.12);
        border-radius: 8px;
        padding: 25px;

        h4 {
            @extend %h4;
            line-height: 33px;
            font-family: $font-secondary;
            color: $black;
        }

        p {
            font-weight: 400;
            @extend %font14;

            span {
                font-weight: 600;
            }
        }
    }

    .e-disabled {
        opacity: 0.7;
        pointer-events: none;
    }
}

.e-filter-field {
    .css-1s2u09g-control,
    .css-1pahdxg-control {
        border: solid 1px #e3e3e3 !important;
        border-radius: 4px !important;
    }
    .input_e_input_box__EL5vM {
        border: solid 1px #e3e3e3 !important;
        border-radius: 4px !important;
        padding: 8px;
    }
}
.e-disabled {
    opacity: 0.7;
    pointer-events: none;
}

.e-auth-footer {
    margin-top: 100px;
}

/*------ Dashboard ------ */
.e-dashboard-container {
    padding-bottom: 240px;

    h6 {
        @extend %h6;
        font-weight: 600 !important;
        color: $grey;
    }

    .e-dashboard-sort {
        .css-1s2u09g-control,
        .css-1pahdxg-control {
            border: 1px solid $input_boder !important;
            box-sizing: border-box !important;
            border-radius: 4px !important;
            color: $grey;
            font-weight: 400;
            min-width: 130px;
        }

        .css-14el2xx-placeholder {
            font-weight: 400 !important;
            @extend %font12;
            color: $grey;
            font-weight: 400;
        }

        .css-319lph-ValueContainer {
            padding: 0px 8px !important;
        }

        .css-qc6sy-singleValue {
            @extend %font12;
            font-weight: 500 !important;
        }

        .css-4ljt47-MenuList {
            @extend %font14;
        }

        // .css-1n7v3ny-option {
        //   font-weight: 400!important;
        //   font-size: 14px!important;;
        //   line-height: 17px;
        //   color: $grey!important;
        // }
        // .css-9gakcf-option:active {
        //   background-color: $white!important;
        //   color: $primary_color!important;
        // }
    }

    .e-dashboard-card {
        box-shadow: 8px 16px 56px rgba(0, 0, 0, 0.04);
        background: $white;
        padding: 24px 24px 24px 12px;
        width: 100%;
        height: 100%;

        .e-card-title {
            @extend %h6;
            font-weight: 600 !important;
            color: $grey;
        }
    }
}

/*------Users-----*/
.e-users-list {
    .css-1s2u09g-control,
    .css-1pahdxg-control {
        background-color: #ffffff !important;
        border-radius: 4px 4px 4px 4px !important;
        width: 100%;
        border: none !important;
        min-height: 42px !important;
        border: solid 1px $border_color !important ;
    }
    .e-name-link {
        border-bottom: 1px solid $secondary_dark;
        &:hover {
            color: $primary_color;
            border-bottom: 1px solid $primary_color;
        }
    }
}
.e-detail-card {
    box-shadow: 8px 16px 56px rgb(0 0 0 / 4%);
    border-radius: 8px !important;
    padding: 24px 35px 6px 35px !important;
    transition: box-shadow 0.3s;
    z-index: 0;
    border: none !important;
    outline: none;
    height: 100%;
    margin-bottom: 25px;
    h6 {
        font-weight: 700 !important;
        color: #010101;
    }
}
.e-name-link {
    border-bottom: 1px solid $secondary_dark;
    &:hover {
        color: $primary_color;
        border-bottom: 1px solid $primary_color;
    }
}
.e-users-container {
    h6 {
        @extend %h6;
        font-weight: 700 !important;
        color: $grey;
    }

    .e-date-sort {
        .css-1s2u09g-control,
        .css-1pahdxg-control {
            border: 1px solid #e3e3e3 !important;
            box-sizing: border-box !important;
            border-radius: 4px !important;
            color: $grey;
            font-weight: 400;
        }

        .css-14el2xx-placeholder {
            font-weight: 400 !important;
            @extend %font14;
            color: $grey;
            font-weight: 400;
        }

        .css-319lph-ValueContainer {
            padding: 0px 8px !important;
        }

        .css-qc6sy-singleValue {
            @extend %font14;
            font-weight: 500 !important;
        }

        .css-4ljt47-MenuList {
            @extend %font14;
        }
    }

    .e-m2e-ant-picker {
        .ant-picker {
            padding: 8px !important;
            border: 1px solid #e3e3e3;
            border-radius: 4px !important;
        }
    }
}
.e-filter {
    display: inline-block;
    .css-b62m3t-container {
        width: 125px;
    }
    .input_e_input_box__EL5vM {
        width: 125px;
    }
}
.e-user-transaction-search {
    .searchInput_e_search_submit__EMirC {
        top: 8px !important;
    }
    .searchInput_e_search_close__g90bk {
        top: 20px !important;
    }
}
#e-user-transaction-date {
    .ant-picker {
        padding: 8px 8px !important;
        .anticon.anticon-calendar {
            background-position-x: 40% !important ;
        }
    }
}
.e-add-admin {
    .css-1s2u09g-control,
    .css-1pahdxg-control {
        min-height: 48px !important;
    }
}
.e-manage-admin-card {
    .css-1s2u09g-control,
    .css-1pahdxg-control {
        min-height: 36px !important;
        border-radius: 4px !important;
        border: 1px solid #e3e3e3 !important;
    }
}
.e-filter-wraper {
    background: #f0f0f0;
    padding: 20px 0px 20px 20px;
    margin-left: 12px;

    label {
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: $grey;
    }
    .input_e_input_box__EL5vM {
        border: solid 1px #e3e3e3 !important;
        border-radius: 4px !important;
        padding: 10px 15px;
    }
}
.e-faq-select {
    .css-1s2u09g-control,
    .css-1pahdxg-control {
        border: none !important;
    }
    .css-14el2xx-placeholder,
    .css-qc6sy-singleValue {
        color: $black;
        font-weight: 600 !important;
    }
    .css-tlfecz-indicatorContainer,
    .css-1gtu0rj-indicatorContainer {
        padding-left: 0px !important;
    }
    .css-1hb7zxy-IndicatorsContainer {
        width: 60px !important;
    }
}

.e-filter-btn.e-active {
    border: 1px solid $primary_color;
}

.e-filter-btn {
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
}

/*--------User Detail--------*/
.e-summary-count {
    font-size: 24px !important;
    font-weight: 700 !important;
}

.e-milestone-wrapper {
    background: #f6f9ff;
    border-radius: 8px;
    padding: 20px 24px;

    h5 {
        font-weight: 700;
        @extend %font16;
        line-height: 20px;
        color: #1f1136;
    }

    p {
        font-weight: 400;
        @extend %font12;
        line-height: 20px;
        color: #1f1136;

        span {
            font-weight: 700;
        }
    }

    h6 {
        font-weight: 600 !important;
        @extend %font14;
        line-height: 17px;
        color: #6d6d6d;
    }

    .e-acheivements {
        font-weight: 400;
        @extend %font12;
        line-height: 16px;
        color: #7d7688;
    }

    .e-count {
        font-weight: 400;
        @extend %font12;
        line-height: 16px;
        text-align: right;
        color: #1f1136;
    }
}

.e-user-detail-sort {
    width: 160px !important;

    .css-1s2u09g-control,
    .css-1pahdxg-control {
        border: 1px solid #6d6d6d !important;
        box-sizing: border-box !important;
        border-radius: 4px !important;
        color: $grey;
        font-weight: 400;
        background-color: #f6f9ff;
    }

    .css-14el2xx-placeholder {
        font-weight: 400 !important;
        @extend %font12;
        color: $grey;
        font-weight: 400;
    }

    .css-319lph-ValueContainer {
        padding: 0px 8px !important;
    }

    .css-qc6sy-singleValue {
        @extend %font12;
        font-weight: 500 !important;
    }

    .css-4ljt47-MenuList {
        @extend %font12;
    }
}

.e-report-download-btn {
    padding: 13px 16px;
    position: relative;
    bottom: 0px;
}

/*------ Manage Games ------*/
.e-game-listing-card {
    height: 884px;
    border-radius: 8px !important;
}

.e-edit-btn {
    color: $secondary_color;
}

.e-delete-btn {
    color: $delete-btn;
}

.e-save-btn {
    color: $primary_color;
}

.e-input-suffix,
.e-input-suffix-rs {
    background-position: 96% center;
    background-repeat: no-repeat;
}

.e-input-suffix {
    background-image: url("../assets/images/dataFormating/time.svg") !important;
}

.e-input-suffix-rs {
    background-image: url("../assets/images/dataFormating/rs.svg") !important;
}

.e-acc-btn {
    padding: 16px 16px !important;
}

.e-block-delete {
    color: $red;
    font-weight: 600;
    @extend %font14;
    line-height: 24px;
}

.e-block-edit {
    color: $secondary_color;
    font-weight: 600;
    @extend %font14;
    line-height: 24px;
}

.e-manage-game-accordion {
    .css-1s2u09g-control,
    .css-1pahdxg-control {
        min-height: 48px !important;
    }
}

.e-game-settings-accordion {
    .hidden {
        display: none !important;
    }

    .e-preview-sec {
        background: #f5f8fe;
        border: 0.5px dashed #7d7688;
        box-sizing: border-box;
        padding: 12px;
        border-radius: 8px;
        min-height: 228px;

        .e-preview-sub-sec {
            display: flex;
            justify-content: center;
            align-items: center;
            max-width: 291px;
            border-radius: 8px;
            min-height: 202px;

            img {
                max-width: 120px;
                min-height: 120px;
            }
        }
    }

    .e-uploader {
        background: #f5f8fe;
        border: 1px dashed #7d7688;
        box-sizing: border-box;
        border-radius: 8px;
        padding: 12px;
        margin-left: 10px;
        cursor: pointer;

        p {
            color: $primary_color;
            font-weight: 600;
            font-size: 14px;
            line-height: 24px;

            span {
                color: #7d7688;
                font-weight: 600;
                font-size: 14px;
            }
        }
        .setting-detail {
            color: #7d7688;
            font-weight: 600;
            font-size: 14px;
        }
    }

    // .e-upload-img-color {

    // }
    .accordion-header {
        padding: 2px;
    }

    .accordion-button {
        border: none !important;
        background-color: $white;
        text-align: left;
        width: 100%;
        position: relative;
        border-radius: 0;
        outline: none;
        font-size: 14px;
        font-weight: 600 !important;
        line-height: 24px;
        display: block;
        color: #010101;
    }

    .accordion-button:not(.collapsed) {
        box-shadow: none;
    }

    .accordion-item {
        font-weight: 600;
        display: block;
        background-color: $white;
        font-size: 14px;
        line-height: 24px;
        color: #010101;
        border-radius: 8px;
        position: relative;

        // &[aria-expanded="false"]{
        .e-accordion-icon-minus {
            position: absolute;
            top: 33%;
            right: 10px;
            background-image: url("../assets/images/dataFormating/minus.svg");
            background-position: 45% 0;
            background-repeat: no-repeat;
            padding: 15px;
            // transform: rotate(0deg) translateY(0%);
            transition: 0.5s ease-in-out;
        }

        // }
        // &[aria-expanded="true"]{
        .e-accordion-icon-plus {
            position: absolute;
            top: 33%;
            right: 10px;
            background-image: url("../assets/images/dataFormating/add.svg");
            background-position: 45% 0;
            background-repeat: no-repeat;
            padding: 15px;
            // transform: rotate(180deg) translateY(50%);
            transition: 0.5s ease-in-out;
        }
    }

    .accordion-item {
        border-radius: 8px !important;
        margin-bottom: 15px;
        border-top: 1px solid rgba(0, 0, 0, 0.125);
    }

    .accordion-button:focus {
        box-shadow: unset;
    }
}

.e-get-list {
    // top: 100%;
    text-align: left;
    background-color: hsl(0, 0%, 100%);
    border-radius: 4px;
    box-shadow: 0 0 0 1px hsl(0deg 0% 0% / 10%), 0 4px 11px hsl(0deg 0% 0% / 10%);
    margin-bottom: 8px;
    margin-top: 8px;
    position: absolute;
    width: 304px;
    height: 180px;
    overflow-y: scroll;
    z-index: 1;
    box-sizing: border-box;
    .e-list-items {
        line-height: 210%;
        @extend %font14;
        margin-top: 4px;
        padding: 6px 14px;
        &:hover {
            background-color: #e5c0fc !important;
            cursor: pointer;
        }
    }
}

.e-get-scrip-list {
    // top: 100%;
    background-color: hsl(0, 0%, 100%);
    border-radius: 4px;
    box-shadow: 0 0 0 1px hsl(0deg 0% 0% / 10%), 0 4px 11px hsl(0deg 0% 0% / 10%);
    margin-bottom: 8px;
    margin-top: 8px;
    position: absolute;
    width: 387px;
    height: 235px;
    overflow-y: scroll;
    z-index: 1;
    box-sizing: border-box;
    .e-list-items {
        line-height: 210%;
        @extend %font14;
        margin-top: 4px;
        padding: 6px 14px;
        // &:hover {
        //     background-color: #e5c0fc !important;
        //     cursor: pointer;
        // }
    }
}
.e-exception-search {
    background: #fff;
    border: transparent;
    box-sizing: border-box;
    border-radius: 4px;
    width: 100% !important;
    display: inline-block;
    padding: 8px 44px 8px 15px !important;
    // background-image: url(http://localhost:3000/static/media/search.0ea1646….svg);
    background-size: 14px;
    background-position: 20px 11px;
    background-repeat: no-repeat;
    font-weight: 500;
    background-color: #f3f3f3 !important;
    color: #000000;
}
.e-add-block-rule {
    .e-suffix {
        left: 80% !important;
        pointer-events: none;
    }
    .css-1s2u09g-control,
    .css-1pahdxg-control {
        min-height: 46px !important;
    }

    .css-1insrsq-control {
        border: solid 1px #a0b5ae !important;
        background-color: #efefef !important;
    }
}
.e-add-block-in {
    .e-suffix {
        left: 71% !important;
        cursor: pointer;
        pointer-events: none;
    }
    .e-block-show {
        width: 148px;
        // height: 172.81px;
        top: 82px;
        background: #ffffff;
        box-shadow: 8px 16px 56px rgba(0, 0, 0, 0.12);
        border-radius: 8px;
        z-index: 10;
        position: absolute;
        right: 0;
        padding: 10px;
        p {
            cursor: pointer;
        }
        .e-selected {
            color: $primary_color;
        }
    }
}

.e-add-block-rule-period {
    .e-suffix {
        left: 76% !important;
        cursor: pointer;
        pointer-events: all !important;
    }
    .e-suffix-class {
        left: 90% !important;
        cursor: pointer;
        pointer-events: all !important;
    }

    .e-block-show {
        width: 148px;
        // height: 172.81px;
        top: 82px;
        background: #ffffff;
        box-shadow: 8px 16px 56px rgba(0, 0, 0, 0.12);
        border-radius: 8px;
        z-index: 10;
        position: absolute;
        right: 0;
        padding: 10px;
        p {
            cursor: pointer;
        }
        .e-selected {
            color: $primary_color;
        }
    }
}

.e-scroll {
    .css-4ljt47-MenuList,
    .css-11unzgr {
        padding: 0px !important;
        &::-webkit-scrollbar {
            border-radius: 10px;
            width: 6px !important;
        }
        &::-webkit-scrollbar-thumb {
            background: #e5c0fc;
            padding: 10px;
            border-radius: 10px;
        }
    }
}
.e-labels {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #010101;
}

.e-span-wrap {
    cursor: pointer;

    .e-profile-active-btn {
        background: $primary_color;
        border-radius: 4px;
        color: $white;
        @extend %font14;
        padding: 9px 10px;
        width: 100%;
        margin-right: 10px;
        font-weight: 400;
        border: 1.5px solid $primary_color;
    }

    .e-profile-btn {
        background: $white;
        border-radius: 4px;
        color: $grey;
        @extend %font14;
        padding: 9px 10px;
        width: 100%;
        margin-right: 10px;
        font-weight: 400;
        border: 1.5px solid #e3e3e3;
    }
}

/*----- Manage Content ------*/
.e-add-faq-btn {
    @extend %font14;
    width: 90%;
    padding: 14px;
}

.e-faq-card {
    min-height: 600px;

    h6 {
        color: $black !important;
    }
}

.e-cancel {
    width: 98px;
    height: 40px;
    padding: 0 !important;
    margin-right: 14px;
}

.e-modal-submit {
    font-weight: 500 !important;
    padding: 0 !important;
    @extend %font14;
    width: 129px;
    height: 40px;
}

.e-breadcrumb-link {
    font-weight: 300;
    @extend %font14;
    line-height: 17px;
    color: rgba(255, 255, 255, 0.87);
    position: relative;
    top: -100%;

    a {
        text-decoration: none;
        color: $primary_color;
        padding-right: 4px;

        &:hover {
            text-decoration: underline;
        }
    }
}

.e-nodata {
    width: 256px;
    height: 202px;
    margin: auto;
}

/*--------Earnings------*/
.e-sort {
    .css-1s2u09g-control,
    .css-1pahdxg-control {
        border: 1px solid #e3e3e3 !important;
        box-sizing: border-box !important;
        border-radius: 4px !important;
        color: #010101;
        font-weight: 400;
        width: 152px;
    }

    .css-14el2xx-placeholder {
        font-weight: 400 !important;
        @extend %font14;
        color: $grey;
        font-weight: 400;
        @extend %font14;
    }

    .css-qc6sy-singleValue {
        @extend %font14;
        font-weight: 400 !important;
    }

    .css-4ljt47-MenuList {
        @extend %font14;
    }
}
.e-search-earnings {
    .e-submit {
        right: 10px !important;
    }
}

/*--------------Milestones-------*/
.e-header-title {
    color: $white;
    position: absolute;
    font-weight: 600;
    font-size: 24px;
    line-height: 130%;
    margin-top: -6% !important;
}

.e-dashboard-select {
    position: absolute;
    margin-top: -6% !important;
    right: 54px;

    .e-dashbaord-dropdown {
        border: 1px solid #6d6d6d;
        box-sizing: border-box;
        border-radius: 4px;
        min-height: 28px;
        background-image: url("../assets/images/dropdown/arrow-down.svg");
        margin-right: 1.25rem;
        margin-bottom: 10px;
        background-repeat: no-repeat;
        background-position: 95% center;
        color: $white;
        display: flex;
        align-items: center;
        padding: 8px 50px 8px 10px;
        font-weight: 400;
        @extend %font12;
        min-width: 130px;
        cursor: pointer;
    }
    .css-1s2u09g-control,
    .css-1pahdxg-control {
        border: 1px solid #6d6d6d !important;
        box-sizing: border-box;
        border-radius: 4px !important;
        min-width: 130px;
        min-height: 36px !important;
        background-color: transparent !important;
        .css-qc6sy-singleValue,
        .css-6j8wv5-Input {
            font-weight: 400;
            @extend %font12;
            color: $white;
        }
        .css-tj5bde-Svg,
        .css-8mmkcg .css-tj5bde-Svg {
            width: 16px;
            height: 16px;
            background-image: url("../assets/images/dropdown/arrow-down.svg");
        }
    }
    .e-show-dropdown {
        position: absolute;
        background-color: $white;
        box-shadow: 8px 16px 56px rgba(0, 0, 0, 0.12);
        padding: 14px;
        border-radius: 8px;
        top: 90%;
        z-index: 1;
        cursor: pointer;
        width: 130px;
        p {
            margin-bottom: 24px;
        }
        .e-arrow-show {
            background-repeat: no-repeat;
            background-position: 95% center;
            background-image: url("../assets/images/dropdown/side-arrow.svg");
        }
    }

    .e-second-show-dropdown {
        position: absolute;
        background-color: $white;
        box-shadow: 8px 16px 56px rgba(0, 0, 0, 0.12);
        padding: 14px;
        border-radius: 8px;
        top: 3%;
        z-index: 2;
        cursor: pointer;
        width: 130px;
        right: 125px;
    }

    // .css-1s2u09g-control,.css-1pahdxg-control{
    //   background-color: transparent!important;
    //   min-height: 20px!important;
    // }
    // .css-qc6sy-singleValue{
    //   color: $white;
}
.e-block-user-modal {
    .ant-picker {
        border: 0.5px solid #6d6d6d !important;
        border-radius: 8px !important;
    }
}
.e-manage-faq {
    .css-1s2u09g-control,
    .css-1pahdxg-control {
        min-height: 46px !important;
    }
}
.date-picker-wrapper {
    right: 50% !important;
}
.e-dashboard-graph-select {
    float: right;
    .e-dashbaord-dropdown {
        border: 1px solid $input_boder;
        box-sizing: border-box;
        border-radius: 4px;
        min-height: 28px;
        background-image: url("../assets/images/dropdown/arrow-down-black.svg");

        margin-right: 1.25rem;
        margin-bottom: 10px;
        background-repeat: no-repeat;
        background-position: 95% center;
        color: $input_boder;
        display: flex;
        align-items: center;
        padding: 8px 50px 8px 10px;
        font-weight: 500;
        @extend %font12;
        min-width: 130px;
        cursor: pointer;
    }
    .css-1s2u09g-control,
    .css-1pahdxg-control {
        border: 1px solid #6d6d6d !important;
        box-sizing: border-box;
        border-radius: 4px !important;
        min-width: 130px;
        min-height: 28px !important;
        background-color: transparent !important;
        .css-qc6sy-singleValue,
        .css-6j8wv5-Input {
            font-weight: 400;
            @extend %font12;
            color: $input_boder;
        }
        .css-tj5bde-Svg,
        .css-8mmkcg .css-tj5bde-Svg {
            color: $input_boder !important;
            width: 16px;
            height: 16px;
            background-image: none;
        }
    }
    .e-show-dropdown {
        position: absolute;
        background-color: $white;
        box-shadow: 8px 16px 56px rgba(0, 0, 0, 0.12);
        padding: 14px;
        border-radius: 8px;
        bottom: 38.5%;
        z-index: 1;
        cursor: pointer;
        width: 130px;
        p {
            margin-bottom: 24px;
        }
        .e-arrow-show {
            background-repeat: no-repeat;
            background-position: 95% center;
            background-image: url("../assets/images/dropdown/side-arrow.svg");
        }
    }
    .e-second-show-dropdown {
        position: absolute;
        background-color: $white;
        box-shadow: 8px 16px 56px rgba(0, 0, 0, 0.12);
        padding: 14px;
        border-radius: 8px;
        top: 3%;
        z-index: 2;
        cursor: pointer;
        width: 130px;
        right: 125px;
    }

    // .css-1s2u09g-control,.css-1pahdxg-control{
    //   background-color: transparent!important;
    //   min-height: 20px!important;
    // }
    // .css-qc6sy-singleValue{
    //   color: $white;
}

.e-add-mile-select {
    .e-dashbaord-dropdown {
        border: 1px solid $input_boder;
        box-sizing: border-box;
        border-radius: 8px;
        min-height: 28px;
        background-image: url("../assets/images/dropdown/arrow-down-black.svg");
        margin-right: 1.25rem;
        margin-bottom: 10px;
        background-repeat: no-repeat;
        background-position: 95% center;
        color: $input_boder;
        display: flex;
        align-items: center;
        padding: 16px 50px 16px 10px;
        font-weight: 400;
        @extend %font14;
        min-width: 100%;
        cursor: pointer;
    }
    .e-show-dropdown {
        position: absolute;
        background-color: $white;
        box-shadow: 8px 16px 56px rgba(0, 0, 0, 0.12);
        padding: 14px;
        border-radius: 8px;
        top: 31%;
        z-index: 1;
        cursor: pointer;
        width: 130px;
        p {
            margin-bottom: 24px;
        }
        .e-arrow-show {
            background-repeat: no-repeat;
            background-position: 95% center;
            background-image: url("../assets/images/dropdown/side-arrow.svg");
        }
    }
    .e-second-show-dropdown {
        position: absolute;
        background-color: $white;
        box-shadow: 8px 16px 56px rgba(0, 0, 0, 0.12);
        padding: 14px;
        border-radius: 8px;
        top: 3%;
        z-index: 2;
        cursor: pointer;
        width: 130px;
        right: -125px;
    }

    // .css-1s2u09g-control,.css-1pahdxg-control{
    //   background-color: transparent!important;
    //   min-height: 20px!important;
    // }
    // .css-qc6sy-singleValue{
    //   color: $white;
}
//leaderboard

.e-leaderboard {
    .ant-picker {
        padding: 8px !important;
    }
}

.e-add-new-milestone {
    .e-ant-range-picker {
        .ant-picker-range .ant-picker-clear {
            top: 26px !important;
        }
    }
}

.e-time-duration {
    background: #ffffff;
    border: 0.5px solid #6d6d6d;
    outline: none;
    width: 100%;
    font-weight: 400;

    padding: 12px 15px;
    border-radius: 8px;
    background-image: url("../assets/images/dropdown/arrow-down-black.svg");
    background-repeat: no-repeat;
    background-position: 96%;
    .e-duration-placeholder {
        color: $grey;
        opacity: 0.27;
    }
    .e-duration-selected {
        color: $grey;
    }
}
.e-show-duration-picker {
    background-color: white;
    border-radius: 4px;
    box-shadow: 0 0 0 1px rgb(0 0 0 / 10%), 0 4px 11px rgb(0 0 0 / 10%);
    margin-bottom: 8px;
    margin-top: 8px;
    position: absolute;
    width: 304px;
    height: 180px;
    overflow-y: scroll;
    z-index: 1;
    box-sizing: border-box;
    .e-duration-value {
        line-height: 210%;
        margin-top: 4px;
        padding: 6px 14px;
        &:hover {
            background-color: #e5c0fc !important;
            cursor: pointer;
        }
    }
}
.e-ant-range-picker {
    .ant-picker-range .ant-picker-clear {
        width: 1.5em !important;
        right: 2px;
        height: 1.2em !important;
        top: 20px !important;
    }
    .ant-picker-active-bar {
        background: $primary_color !important;
    }
    .ant-picker {
        padding: 8px 7px !important;
    }
    .ant-picker:hover {
        border-color: $border_color !important;
    }
    .ant-picker-focused {
        border-color: $border_color !important;
        box-shadow: none !important;
    }
    .anticon.anticon-calendar {
        background-position-x: 46% !important ;
    }
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    background: $primary_color;
}
.ant-picker-cell-in-view.ant-picker-cell-in-range::before,
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
    background: #f3e1fe !important;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border: 1px solid $primary_color !important;
}
.e-earnings {
    .searchInput_e_search_submit__EMirC {
        right: 10px !important;
    }
    .searchInput_e_search_close__g90bk {
        right: 58px !important;
    }
    .searchInput_e_search_input_box__SDZ6a {
        width: 92% !important;
    }
    .css-qc6sy-singleValue {
        @extend %font14;
    }
}

.e-piechart-label-type {
    p,
    span {
        @extend %font12;
        color: #5c6370;
        font-weight: 400;
        line-height: 18px;
    }
}
.e-earning-download-btn {
    padding: 0px 10px !important;
    height: 34px;
}

.e-tab-position {
    margin-top: -6% !important;
    position: absolute;
}

.e-milestone-wrap {
    padding: 24px;
}

.e-month-sort {
    .css-1s2u09g-control,
    .css-1pahdxg-control {
        width: 172px !important;
        color: #6d6d6d;
    }

    .css-14el2xx-placeholder {
        color: #6d6d6d;
        @extend %font14;
    }
}
.e-bookings-input {
    input {
        // width: 145px;
        border: 1px solid #e3e3e3 !important;
        box-sizing: border-box !important;
        border-radius: 4px !important;
        color: #010101;
        font-weight: 400;
        padding: 9px 15px !important;
        &::placeholder {
            font-weight: 400;
            line-height: 18px;
            color: #010101 !important;
            @extend %font14;
            opacity: 10 !important;
        }
    }
}

.e-bookings-sort {
    .css-1s2u09g-control,
    .css-1pahdxg-control {
        border: 1px solid #e3e3e3 !important;
        box-sizing: border-box !important;
        border-radius: 4px !important;
        color: #010101;
        font-weight: 400;
    }

    .css-14el2xx-placeholder {
        color: #6d6d6d;
        @extend %font14;
    }
}

.e-push-notification {
    .e-ant-range-picker .ant-picker,
    .e-ant-range-picker .ant-picker-focused,
    .ant-picker {
        padding: 12px 16px !important;
        border: 0.5px solid #6d6d6d;
        border-radius: 8px !important;
        border-color: #6d6d6d !important;
    }
    .e-ant-range-picker .ant-picker-range .ant-picker-clear {
        width: 1.5em !important;
        right: 10px;
        height: 1.2em !important;
        top: 22px !important;
    }
}

.e-toggle-status {
    .e-slider::before {
        top: 2.2px !important;
    }
}

.e-scrips-list {
    height: 300px;
    overflow-y: scroll;
    &::-webkit-scrollbar {
        // border-radius: 10px;
        width: 8px !important;
        height: 0px;
    }
    &::-webkit-scrollbar-thumb {
        background: #e5c0fc;
        padding: 10px;
        // border-radius: 10px;
    }
}

/*Add milestone*/
.e-add-milestone-wrap {
    // .e_input_box{
    //   color: #7D7688!important;
    //   &::placeholder{

    //     color: #7D7688!important;
    //     opacity: 1!important;

    //   }
    // }
    .e-milestone-sort {
        .css-1s2u09g-control,
        .css-1pahdxg-control {
            border: 0.5px solid #6d6d6d !important;
            box-sizing: border-box !important;
            border-radius: 8px !important;
            color: #7d7688;
            font-weight: 400;
            width: 100%;
            min-height: 54px !important;
        }

        .css-14el2xx-placeholder {
            font-weight: 400 !important;
            @extend %font14;
            color: #7d7688;
            font-weight: 400;
            @extend %font14;
        }

        .css-319lph-ValueContainer {
            padding: 10px 8px !important;
        }

        .css-qc6sy-singleValue {
            @extend %font14;
            font-weight: 400 !important;
        }
    }
}

.e-add-new-milestone {
    .input_e_input_box__EL5vM {
        padding: 16px 15px !important;
    }
    .e-ant-range-picker .ant-picker {
        border: 0.5px solid #6d6d6d !important;
        padding: 16px 9px !important;
        border-radius: 8px !important;
    }
}

/*----------Scrip & voting--------*/
.e-add-scrip-btn {
    padding: 16px 16px;
}

.e-work-link {
    color: $primary_color !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    letter-spacing: 1px;
}

/*--------- Manage admin--------- */
.e-manage-admin-card {
    border-radius: 8px;
}

.e-invite-admin-btn {
    width: 116px;
    height: 48px;
    padding: 0;
}

/*--------- Manage content--------- */
// .e-content-tab {
//   // position: absolute;
//   // top: 16%;
// }
.e-add-question-btn {
    padding: 14px 18px !important;
}

.e-currnt-btn {
    font-weight: 700 !important;
}

.e-editor-save {
    padding: 12px 18px !important;
}

.e-bounce {
    -moz-animation: bounce 2s infinite;
    -webkit-animation: bounce 2s infinite;
    animation: bounce 2s infinite;
}

@keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
    }

    40% {
        transform: translateY(-5px);
    }

    60% {
        transform: translateY(-10px);
    }
}
/* loader */
.e-btn-loader span {
    &:after {
        content: " .";
        font-family: "Epilogue";
        margin: 0;
        line-height: 100%;
        animation: dots 1s steps(1, end) infinite;
    }
}

@keyframes dots {
    0%,
    12.5% {
        opacity: 0;
    }

    25% {
        opacity: 1;
    }

    50% {
        text-shadow: 0.2em 0;
    }

    75%,
    100% {
        text-shadow: 0.2em 0, 0.4em 0;
    }
}

.e-image-loader {
    &:after {
        content: " .";
        font-family: "Epilogue";
        margin: 0;
        line-height: 100%;
        animation: dotsImage 1s steps(1, end) infinite;
    }
}

@keyframes dotsImage {
    0%,
    12.5% {
        opacity: 0;
    }

    25% {
        opacity: 1;
    }

    50% {
        text-shadow: 0.2em 0;
    }

    75%,
    100% {
        text-shadow: 0.2em 0, 0.4em 0;
    }
}

.e-loader {
    &:after {
        content: " .";
        font-family: "Epilogue";
        margin: 0;
        line-height: 100%;
        animation: dotss 1s steps(1, end) infinite;
    }
}

@keyframes dotss {
    0%,
    12.5% {
        opacity: 0;
    }

    25% {
        opacity: 1;
    }

    50% {
        text-shadow: 0.1em 0;
    }

    75%,
    100% {
        text-shadow: 0.2em;
    }
}

@keyframes dots {
    0%,
    12.5% {
        opacity: 0;
    }

    25% {
        opacity: 1;
    }

    50% {
        text-shadow: 0.2em 0;
    }

    75%,
    100% {
        text-shadow: 0.2em 0, 0.4em 0;
    }
}

/*---Tax settings-----*/
.e-tax-container {
    h6 {
        @extend %font14;
        color: #010101;
        font-weight: 400;
        line-height: 24px;
    }

    .e-cancel-tax {
        padding: 13px 20px !important;
    }
}

/*---------Platform settings----*/
.e-platform-settings-accordion {
    .e-add-to-blocklist {
        padding: 14px 20px;
    }

    .ant-picker {
        border: 0.5px solid $input_boder !important;
        border-radius: 8px !important;
    }

    .e-previous-blocked {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #010101;
        opacity: 0.87;
    }

    .e-blocked-span {
        background-color: #f3f6fc;
        border-radius: 8px;
        color: #1f1136;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 120%;
        padding: 12px 20px 12px 14px;
        display: inline-block;
    }

    .e-close-icon {
        cursor: pointer;
        position: relative;
        right: -10px;
        top: -1px;
    }
}
/*---- report styles ----*/
.e-ssp-tabs {
    overflow-x: scroll;
    width: 98.5%;
    .e-tab,
    .e-active-tab {
        text-align: center;
        min-width: 115px;
        padding: 12px !important;
    }
}
