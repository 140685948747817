/*
 *   File : milestoneCard.module.scss
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Miestone card style
 *   Integrations :
 *   Version : v1.0
 *   Created : 16-02-2022
 */

@import "./../config.scss";

.e_milestone_card {
  background: #f4f7fd;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 16px;
  h6 {
    color: #1f1136;
    font-weight: 600;
    font-size: 16px !important;
    line-height: 24px;
  }
  label {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #7d7688;
    span {
      font-weight: 700!important;
      font-size: 12px;
      line-height: 20px;
      color: $grey;
    }
  }
  p {
    font-weight: 700!important;
    font-size: 12px;
    line-height: 20px;
    color: $grey;
  }
  span {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: $primary_color;
  }
  .e_icon {
    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }
  }
}
