/*
 *   File : input.module.scss
 *   Author : https://evoqueinnovativelab.com
 *   Description : InputBox style
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 27-10-2021
 */

@import "./../config.scss";

.e_input_box {
  @extend %font14;
  background: $white;
  border: 0.5px solid $input_boder;
  outline: none;
  width: 100%;
  font-weight: 400;
  color: $dark_grey;
  padding: 12px 15px;
  border-radius: 8px;
  &::placeholder {
    font-weight: 400;
    line-height: 18px;
    color: $grey;
    opacity: 0.27;
    @extend %font14;
  }
  &:disabled {
    border: 0.5px solid $light_grey;
    opacity: 0.6;
    background-color: #efefef;
    color: #7d7688;
  }
}
.e_input_box:focus {
  border: 0.5px solid $grey;
}
.e_input_error {
  border: 1px solid $error;
}
.e_error_msg {
  font-size: 12px !important;
  color: $error !important;
}
label {
  font-weight: 400;
  @extend %font12;
  line-height: 18px;
  color: $grey;
  opacity: 0.87;
}
.e_suffix {
  color: #1f1136;
  position: relative;
  left: 90%;
  top: -35px;
  font-size: 14px;
  font-weight: 500;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
