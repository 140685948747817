/*
* @Author: Evouqe Innovative Lab
* @Date:   2021-11-16 17:35:19
* @Last Modified time: 2021-11-12 18:32:23
*/

@import "./../config.scss";

.e_fund_card{
  .e_inner_card{
    background-color: $white;
    border-radius: 8px;
    height: 138px;
    box-shadow: 8px 16px 56px rgba(0, 0, 0, 0.04);
    .e_logo{
      width: 32px;
      height: 32px;
      object-fit: cover;
    }
    .e_inner_card_title{
      color: $dark_grey;
      @extend  %font14;
      line-height: 150%;
      font-weight: 600;
    }
    label{
      color: $light_grey;
    }
    .e_returns{
      color: $dark_grey;
      @extend %font12;
      font-weight: 700;
      span{
        font-weight: 400!important;
      }
    }
  }
}