/*
 *   File : primaryLink.Text.module.scss
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Primary Link Text Button Style
 *   Integrations : null
 *   Version : v1.0
 */

@import "./../config.scss";

.e_text_btn{
  color: $primary_color;
  font-weight: 700;
  @extend %font14;
  border: none;
  outline: none;
  line-height: 17px;
  position: relative;
  transition: background-size 0.3s linear;
  text-decoration: none;
  background: linear-gradient(to right, #9C1FE9 0%,#9C1FE9 98%);
  background-size: 0px 2px;
  background-repeat: no-repeat;
  background-position: left 85%;
  padding: 0 0 7px 0;
  position: relative;
  &:hover {
    background-size: 100% 2px;
  }
  &:after{
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    transition: all .35s;
    z-index: 1;
    color: $black!important;
  }
  &:hover:after{
    width: 100%;
  }
  img {
    padding-right: 6px;
    position: relative;
    top: -2px;
  }
}