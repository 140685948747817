/*
 *   File : loader.module.scss
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : loader
 *   Integrations :
 *   Version : v1.0
 *   Created : 10-11-2021
 */

@import "./../config.scss";

.e_loader {
  background-color: $white;
  height: 70vh;
  padding: 0 !important;
  width: auto !important;
  img {
    width: 150px;
    height: 150px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -50px 0px 0px -50px;
  }
}
