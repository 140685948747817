/*
 *   File : tab.module.scss
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Tab Style
 *   Integrations :
 *   Version : v1.0
 *   Created : 25-01-2022
 */

@import "./../config.scss";

.e_tab {
  @extend %font14;
  font-weight: 600;
  line-height: 120%;
  color: #7d7688;
  transition: ease 0.3s;
  padding-bottom: 5px;
  position: relative;
  &:before {
    content: "";
    position: absolute;
    width: 0;
    height: 3px;
    background-color: $primary_color;
    bottom: 0;
    transition: all 0.35s;
  }
  &:hover {
    &:before {
      width: 100%;
    }
    cursor: pointer;
    opacity: 1;
  }
}
.e_active_tab {
  @extend .e_tab;
  transition: ease 0.3s;
  opacity: 1;
  color: #1f1136;
  font-weight: 600;
  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: $primary_color;
    bottom: 0px;
    left: 0;
  }
}

.e_scrip_tab{
  @extend %font24;
  font-weight: 600;
  line-height: 120%;
  color:  #7D7688;
  transition: ease 0.3s;
  // position: relative;
  
  &:hover{    
    cursor: pointer;
    opacity: 1;
  }
}
.e_active_scrip_tab{
  @extend .e_scrip_tab;
  transition: ease 0.3s;
  opacity: 1;
  color:  $white;
  font-weight: 600;
}