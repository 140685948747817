/*
 *   File : Secondary.module.scss
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Secondary Button style
 *   Integrations : 
 *   Version : v1.0
 */

@import "./../config.scss";

.e_s_btn {
  border: 1.5px solid #7D7688;
  color: #7D7688!important;
  background:  $white;
  outline: none;
  padding: 16px 32px;
  color: $primary_color;
  font-weight: 700!important;
  @extend %font14;
  line-height: 120%;
  transition: all .35s;
  border-radius: 8px;
  z-index: 0;
  position: relative;
  // width: 100%;
  &:hover{
    opacity: 0.6;
    // color: $white;
    // border: 1px solid $secondary_color;
  }
  &:after{
    // position: absolute;
    // content: "";
    // top: -1px;
    // left: 0;
    // width: 0;
    // transition: all .35s;
    // z-index: 1;
    // opacity: 0;
    // color: $white!important;
    // background: $dark_green;
    // border: 1px solid $dark_green;
    // border-radius: 8px;
    // height: calc(100% + 2px);
  }
  &:hover:after{
    // opacity: 1;
    // width: 100%;
    // box-shadow: none!important;
    // border-radius: 8px;
  }
  span {
    z-index: 2;
    position: relative;
  }
}


