/*
 *   File : gameCard.module.scss
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Game card style
 *   Integrations :
 *   Version : v1.0
 *   Created : 10-02-2022
 */

@import "./../config.scss";

.e_game_card {
  border-radius: 8px;
  padding: 16px;
  h6 {
    color: #fff!important;
    font-weight: 700;
    font-size: 18px!important;
    line-height: 24px;
  }
  
}
