/*
 *   File : selectBox.module.scss
 *   Author : https://evoqueinnovativelab.com
 *   Description : SelectBox style
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 09-11-2021
 */

@import "./../config.scss";

.css-1s2u09g-control {
  border: 1px solid $input_boder !important;
  border-radius: 8px !important;
  color: $dark_grey;
  min-height: 40px !important;
  background-color: $white !important;
  &:hover {
    cursor: pointer;
  }
}
.css-1insrsq-control {
  // border: 1px solid $light_grey!important;
  border-radius: 8px !important;
  color: $dark_grey;
  min-height: 48px !important;
  background-color: $white !important;
  &:hover {
    cursor: pointer;
  }
}
.css-14el2xx-placeholder {
  font-weight: 300 !important;
  @extend %font14;
  color: $grey;
}
.css-26l3qy-menu {
  z-index: 11;
}

.css-qc6sy-singleValue {
  font-weight: 400 !important;
  @extend %font14;
}
.css-1pahdxg-control {
  box-shadow: none !important;
  border: 0.5px solid $input_boder !important;
  border-radius: 8px !important;
  min-height: 40px !important;
  &:hover {
    box-shadow: none !important;
    border: 0.5px solid $input_boder !important;
    border-radius: 8px !important;
  }
}
.css-319lph-ValueContainer {
  // padding: 6px 8px!important;
}
.css-1okebmr-indicatorSeparator {
  display: none !important;
}
.css-tlfecz-indicatorContainer {
  color: transparent !important;
}
.css-1gtu0rj-indicatorContainer {
  color: transparent !important;
  &:hover {
    color: transparent !important;
    cursor: pointer;
  }
}
.css-9gakcf-option {
  background-color: $primary_color !important;
  @extend %font14;
}
.css-1n7v3ny-option {
  background-color: #e5c0fc !important;
  @extend %font14;
}
.css-tj5bde-Svg,
.css-8mmkcg {
  background-image: url(../../assets/images/dropdown/icon.svg);
  // transform: rotate(180deg)!important;
  background-repeat: no-repeat;
  background-position: 100% center;
}
.e-m2m-slot {
  .css-26l3qy-menu {
    width: 180px;
  }
}
