@charset "utf-8";
/*------------------------------------------------------------------
[Master Stylesheet]

Project:    Admin Template
Version:    1.0.0
Created:    08-11-2021
Use:
-------------------------------------------------------------------*/

/*--- 1. Import Fonts ----*/

$font-primary: "Inter";
$font-secondary: "Sora";

@font-face {
  font-family: $font-primary;
  src: url(../assets/fonts/Inter/Inter-ExtraBold.ttf);
  font-weight: 800;
}
@font-face {
  font-family: $font-primary;
  src: url(../assets/fonts/Inter/Inter-Bold.ttf);
  font-weight: 700;
}
@font-face {
  font-family: $font-primary;
  src: url(../assets/fonts/Inter/Inter-SemiBold.ttf);
  font-weight: 600;
}
@font-face {
  font-family: $font-primary;
  src: url(../assets/fonts/Inter/Inter-Medium.ttf);
  font-weight: 500;
}
@font-face {
  font-family: $font-primary;
  src: url(../assets/fonts/Inter/Inter-Regular.ttf);
  font-weight: 400;
}
@font-face {
  font-family: $font-primary;
  src: url(../assets/fonts/Inter/Inter-Light.ttf);
  font-weight: 300;
}

@font-face {
  font-family: $font-secondary;
  src: url(../assets/fonts/Sora/static/Sora-ExtraBold.ttf);
  font-weight: 800;
}
@font-face {
  font-family: $font-secondary;
  src: url(../assets/fonts/Sora/static/Sora-Bold.ttf);
  font-weight: 700;
}
@font-face {
  font-family: $font-secondary;
  src: url(../assets/fonts/Sora/static/Sora-SemiBold.ttf);
  font-weight: 600;
}
@font-face {
  font-family: $font-secondary;
  src: url(../assets/fonts/Sora/static/Sora-Medium.ttf);
  font-weight: 500;
}
@font-face {
  font-family: $font-secondary;
  src: url(../assets/fonts/Sora/static/Sora-Regular.ttf);
  font-weight: 400;
}
@font-face {
  font-family: $font-secondary;
  src: url(../assets/fonts/Sora/static/Sora-Light.ttf);
  font-weight: 300;
}

/* --- 2. Colors --- */
$primary_color: #9c1fe9;
$primary_gradient: linear-gradient(90deg, #9c1fe9 0%, #4a06b8 100%);
$primary_hover: linear-gradient(90deg, #4a06b8 0%, #9c1fe9 100%);
$secondary_color: #1f1136;
$secondary_dark: #130727;
$grey: #010101;
$light_blue: #f6f9ff;

$dark_green: #2e6a0f;
$white: #ffffff;
$black: #000000;
$dark_grey: #2b4240;
$light_grey: #a0b5ae;
$card_bg: #f2f6f6;
$toast_success_border: #00d1b1;
$toast_success_bg: #d4ece8f5;
$toast_success_color: #089487;
$toast_fail_bg: #f9e5e5;
$input_boder: #6d6d6d;
$input_placeholder: #9ea3aa;
$error: #ff523b;
$accordion_bg: #fafafa;
$delete: #ef4a3c;
$badge_fail: #ef4a3c;
$footer_bg: #f2f6f6;
$green: #16a377;
$orange: #ffb61d;
$red: #f93059;
$off_white: #f3f3f3;
$light_green: rgba(22, 163, 119, 0.1);
$search_bg: #f3f3f3;
$notification_boder: #f0f0f0;
$secondary_grey: #4f4f4f;
$delete-btn: #eb5757;
$red: #eb5757;
$border_color: #e3e3e3;

/* --- 3. Font --- */
%font10 {
  font-size: 0.625rem;
}
%font11 {
  font-size: 0.688rem;
}
%font12 {
  font-size: 0.75rem;
}
%font14 {
  font-size: 0.875rem;
}
%font16 {
  font-size: 1rem;
}
%font18 {
  font-size: 1.125rem;
}
%font20 {
  font-size: 1.25rem;
}
%font24 {
  font-size: 1.5rem;
}
%font32 {
  font-size: 2rem;
}
%font36 {
  font-size: 2.25rem;
}
%font48 {
  font-size: 3rem;
}

/* --- 2. Global --- */
%heading {
  color: $dark_grey;
  font-weight: 600 !important;
  -webkit-font-smoothing: antialiased;
}
%h4 {
  color: $dark_grey;
  @extend %font24;
  font-weight: 700;
  line-height: 120%;
  -webkit-font-smoothing: antialiased;
}
%h6 {
  color: $input_boder;
  @extend %font14;
  line-height: 17px;
  font-weight: 700;
}
.modal-dialog {
  max-width: 696px !important;
  // box-shadow: 4px 4px 48px rgba(7, 0, 124, 0.08);
  // height: 556px!important;
}
.modal-content {
  padding: 15px !important;
  border-radius: 8px !important;
}
.modal-header {
  border-bottom: none !important;
}
.btn-close {
  opacity: 10 !important;
  cursor: pointer;
}
.btn-close:hover {
  transform: scale(1.1);
}
.btn-close:focus {
  outline: 0;
  box-shadow: none !important;
  opacity: 1;
}

/* ---3. Mixin styles ---*/

// primary button
@mixin hover_animation() {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  transition: all 0.35s;
  z-index: 1;
  opacity: 0;
}

/* ---4. check box ---*/
input[type="checkbox"] {
  position: relative;
  cursor: pointer;
}
input[type="checkbox"]:before {
  content: "";
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  left: 0;
  border: 2px solid #6d6d6d;
  border-radius: 3px;
  background-color: $white;
}
input[type="checkbox"]:checked:after {
  content: "";
  display: block;
  width: 5px;
  height: 10px;
  border: 2px solid $primary_color;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  top: 2px;
  left: 6px;
}
