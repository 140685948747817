/*
 *   File : summary.module.scss
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : summary.module.scss
 *   Integrations :
 *   Version : v1.0
 */

@import "./../config.scss";

/* style for card summary  in dashboard */
.e_summary_card {
  background-color: $white;
  box-shadow: 8px 16px 56px rgba(0, 0, 0, 0.04);
  border-radius: 12px !important;
  padding: 24px 24px !important;
  transition: box-shadow 0.3s;
  // height: 134px;
  z-index: 0;
  border: none !important;
  outline: none;
  min-height: 158px;
  &:hover {
    box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
  }
  .e_prefix {
    font-weight: 600;
    @extend %font14;
    line-height: 17px;
    color: $grey;
  }
  .e_summary_card_icon {
    height: 32px;
    width: 32px;
    object-fit: cover;
  }
  h5 {
    font-size: 13px;
    line-height: 18px;
    -webkit-font-smoothing: antialiased;
    z-index: 1;
    padding-bottom: 20px;
    font-weight: 700;
    color: $grey;
  }
  span {
    font-weight: 600;
    @extend %font16;
    line-height: 22px;
    color: $grey;
    .e_text {
      @extend %font18;
      font-weight: 600;
      padding-left: 2px;
    }
  }
}
.e_summary_card_icon {
  z-index: 0;
  position: relative;
  top: 5px;
  width: 32px;
  height: 32px;
}
