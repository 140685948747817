/*
 *   File : acccordion.module.scss
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : FAQ Accordion style
 *   Integrations :
 *   Version : v1.0
 */

@import "./../config.scss";

.e_faq_accordion {
  .e_accordion_button {
    border: none !important;
    background-color: $white;
    text-align: left;
    width: 100%;
    position: relative;
    border-radius: 0;
    outline: none;
    //  &[aria-expanded="false"]{
    .e_accordion_icon {
      position: absolute;
      top: 35%;
      right: 0px;
      background-image: url(../../assets/images/manage-content/downarrow.svg);
      background-repeat: no-repeat;
      padding: 13px;
      transition: 0.5s ease-in-out;
    }
    //  }
    //  &[aria-expanded="true"]{
    .e_accordion_icon_minus {
      position: absolute;
      top: 35%;
      right: 0px;
      background-image: url(../../assets/images/manage-content/uparrow.svg);
      background-repeat: no-repeat;
      padding: 13px;
      transition: 0.5s ease-in-out;
    }
    //  }
  }
  .e_accordion_item {
    border: none !important;
    padding: 10px 0px !important;
  }
  .e_faq_accordion_header {
    line-height: 18px;
  }
  .e_accordion_title {
    font-weight: 700;
    @extend %font14;
    line-height: 20px;
    color: $black;
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
  .e_accordion_wrapper {
    background: #f5f7fd;
    border-radius: 8px;
    padding: 10px;
    .e_accordion_content {
      font-weight: 500;
      @extend %font14;
      line-height: 160%;
      color: #0d041d;
    }
  }
  .e_delete {
    color: #de2020;
    font-weight: 700;
    display: inline-flex;
    &:hover {
      //  opacity: 0.7;
    }
  }
  .e_edit {
    font-weight: 700;
    display: inline-flex;
    &:hover {
      //  opacity: 0.7;
    }
  }
}
.e_accordion_body {
  padding: 0 0 !important;
}
