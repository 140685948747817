 /*
 *   File : confirmation.module.scss
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : FAQ Accordion style
 *   Integrations :
 *   Version : v1.0
 */

@import "./../config.scss";
.e_confirmation{
  .e_modal_dialog {
    max-width: 590px !important;
  }
  h6 {
    font-weight: 700;
    font-size: 16px;
    color: $grey;
  }
  p {
    font-weight: 400;
    font-size: 14px;
    color: $grey;
  }
}