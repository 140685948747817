/*
 *   File : userDetail.module.scss
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : userDetail.module.scss
 *   Integrations :
 *   Version : v1.0
 */

@import "./../config.scss";

.e_user_detail_card {
  background-color: $white;
  box-shadow: 8px 16px 56px rgba(0, 0, 0, 0.04);
  border-radius: 8px !important;
  padding: 24px !important;
  transition: box-shadow 0.3s;
  z-index: 0;
  border: none !important;
  outline: none;
  .e_user_icon {
    background: #edf3ff;
    border-radius: 100px;
    // width: 120px;
    height: 130px;
    text-align: center;
    // padding: 48px 45px;
    font-weight: 600;
    font-size: 24px;
    line-height: 130%;
    color: $primary_color;
    width: 164px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .e_kyc_verified {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #219653 !important;
  }
  .e_kyc_pending {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: $red !important;
  }
  h4 {
    font-weight: 600;
    font-size: 24px;
    line-height: 130%;
    color: $grey;
  }
  .e_span {
    padding: 20px 24px;
  }
  .e_labels {
    font-weight: 500;
    @extend %font14;
    line-height: 130%;
    color: #6d6d6d;
    display: flex;
    align-items: flex-end;
  }
  .e_rank {
    color: $primary_color;
    font-size: 20px;
    font-weight: 500;
  }
  .e_label_earnings {
    font-weight: 400;
    @extend %font16;
    line-height: 20px;
    color: #7d7688;
  }
  .e_edit {
    border-bottom: solid 1px #9c1fe9;
    cursor: pointer;
    margin-left: 4px;
    &:hover {
      transform: scale(1.05);
    }
  }
  .e_earnings {
    font-weight: 700;
    line-height: 24px;
    color: #1f1136;
    @extend %font18;
  }
  .e_wallet_wrapper {
    background: #faf2ff;
    border-radius: 8px;
    padding: 13px 0 13px 13px;
    width: 145px;
    margin-left: auto;
  }
  p {
    font-weight: 600;
    @extend %font12;
    line-height: 15px;
    color: #7d7688;
  }
  .e_wallet_balance {
    font-weight: 700;
    @extend %font18;
    line-height: 22px;
    text-transform: capitalize;
    color: #2b4240;
  }
  .e_user_id {
    font-size: 12px;
    line-height: 130%;
    color: #6d6d6d;
    font-weight: 500;
    font-size: 12px;
    line-height: 130%;
    color: #6d6d6d;
    span {
      font-weight: 800 !important;
      color: #1f1136;
      font-size: 12px;
    }
  }
  .e_last_login {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #7d7688;
    span {
      font-weight: 700;
      @extend %font12;
      line-height: 16px;
      color: #1f1136;
    }
  }
  .e_toggle_active {
    color: #219653 !important;
    font-size: 14px;
    font-weight: 500;
  }
  .e_toggle_deleted {
    color: $black;
    font-size: 14px;
    font-weight: 500;
  }
  .e_toggle_inactive {
    color: $black;
    font-size: 14px;
    font-weight: 500;
  }
  .e_toggle_deleted_active {
    color: $red;
    font-size: 14px;
    font-weight: 500;
  }
}
