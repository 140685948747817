.e-time-picker {
    .ant-picker {
        width: 100%;
        background: #ffffff;
        border: 0.5px solid $input_boder;
        outline: none;
        width: 100%;
        font-weight: 400;
        color: #2b4240;
        padding: 14px 32px 14px 15px !important;
        border-radius: 8px !important;
    }
    .ant-picker-input > input:placeholder-shown {
        font-weight: 300;
        line-height: 18px;
        color: $grey;
        opacity: 0.35;
        @extend %font14;
    }
    .ant-picker-range .ant-picker-clear {
        right: 50px;
        top: 26px !important;
    }
    .e-input-suffix {
        background-repeat: no-repeat !important;
        background-position: 97% !important;
    }
}
