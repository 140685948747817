@import "./../config.scss";

$md-radio-border-color: $primary_color;
$md-radio-size: 21.33px;
$md-radio-checked-size: 12px;

.e_radiobutton {
  margin: 14px 0;

  input[type="radio"] {
    display: none;

    &:checked + label:before {
      border-color: $primary_color;
    }

    &:checked + label:after {
      transform: scale(1);
    }
  }

  label {
    display: inline-block;
    height: $md-radio-size;
    position: relative;
    padding: 3px ($md-radio-size + 10px);
    margin-bottom: 0;
    cursor: pointer;
    vertical-align: bottom;
    @extend %font14;
    font-weight: 600 !important;
    color: $primary_color;
    &:before,
    &:after {
      position: absolute;
      content: "";
      border-radius: 50%;
      transition: all 0.3s ease;
      transition-property: transform, border-color;
    }
    // &:hover {
    //   color: $primary_color;
    // }

    &:before {
      left: 0;
      top: 0;
      width: $md-radio-size;
      height: $md-radio-size;
      border: 2px solid $md-radio-border-color;
    }

    &:after {
      top: calc($md-radio-size / 2) - calc($md-radio-checked-size / 2);
      left: 4.6875px;
      width: $md-radio-checked-size;
      height: $md-radio-checked-size;
      transform: scale(0);
      background: $primary_color;
    }
  }
}
