/*
 *   File : addFaq.module.scss
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : FAQ Accordion style
 *   Integrations :
 *   Version : v1.0
 */

@import "./../config.scss";
.e_add_faq {
  h6 {
    font-weight: 700;
    font-size: 16px;
    color: $grey;
  }
  .secondary_e_s_btn__39sgM {
    text-align: center !important;
  }

  label {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #010101;
    opacity: 0.87;
  }
  input,
  textarea {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #010101 !important;
  }
}
.e_disabled {
  opacity: 0.7;
  pointer-events: none;
}
