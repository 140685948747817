/*
 *   File : dataTable.module.scss
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : dataTable.module.scss
 *   Integrations :
 *   Version : v1.0
 */

.rdt_TableCol {
  color: $grey !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  line-height: 19px !important;
  -webkit-font-smoothing: antialiased;
  &:hover {
    color: rgba(0, 0, 0, 0.54);
  }
}
.rdt_TableRow {
  // padding: 10px;
}
.hkcGBu {
  font-weight: unset !important;
}
.rdt_TableHeader {
  display: none !important;
}
.sc-hHEiqL::-webkit-scrollbar {
  display: none !important;
}
.rdt_TableHeadRow {
  border-bottom-color: transparent !important;
  background: rgba(255, 255, 255, 0.67) !important;
}
.rdt_TableCell,
.fvAeSn a {
  color: #130727;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 18px !important;
  -webkit-font-smoothing: antialiased;
  text-decoration: none;
}
.rdt_TableBody {
  cursor: pointer;
  .sc-jrQzAO.fhCFWO.rdt_TableRow:nth-of-type(odd) {
    background: rgba(13, 13, 13, 0.02);
  }
}

.rdt_TableRow {
  transition: ease 0.3s;
  &:hover {
    background-color: #f1f7f2 !important;
  }
}
.fhCFWO:not(:last-of-type),
.sc-jrQzAO:not(:last-of-type) {
  border-bottom-color: transparent !important;
}
.bWTWQu {
  color: $dark_grey;
  font-weight: 400 !important;
  @extend %font14;
  line-height: 17px;
}
.kNbUsB {
  background: #f3f3f3 !important;
  border-radius: 4px;
}
.gPLhoV {
  margin-right: 10px !important;
  position: relative;
  border-radius: 8px !important;
  background-color: #f3f3f3 !important;
}
.gqExIP {
  display: none;
}
.jbTKDn {
  background-image: url(../../assets/images/dataFormating/table_sort.png);
  background-repeat: no-repeat;
  background-position: 28% 50%;
  background-size: 12px;
}
.sc-kDTinF {
  color: transparent !important;
  right: 12%;
  // position: absolute;
}
.hLGwHm {
  background-image: url(../../assets/images/dataFormating/table_sort_up.png);
  background-repeat: no-repeat;
  background-position: 100% 50%;
  background-size: 12px;
}
.e-time {
  @extend %font12;
}
.e-status-badge {
  border-radius: 20px;
  font-weight: 600;
  @extend %font12;
  line-height: 14px;
  text-align: center;
  background: $primary_color;
  border-radius: 100px;
  padding: 3px 15px 4px;
  color: $white;
  text-transform: capitalize;
  width: 80px;
}
.e-status-badge.e-status-badge-fail {
  background: $badge_fail;
  color: $white;
}
.e-status-badge.e-status-badge-pending {
  background: #ffb61d;
  color: $white;
}
.e-icon {
  background-image: url(../../assets/images/dataFormating/Icon.png);
  width: 15px;
  height: 15px;
  background-size: 15px;
  background-repeat: no-repeat;
  display: inline-block;
  cursor: pointer;
  position: relative;
  right: -10px;
  top: 4px;
  z-index: 0;
}
.e-more {
  background-image: url(../../assets/images/dataFormating/more.svg);
  width: 15px;
  height: 15px;
  background-size: 15px;
  background-repeat: no-repeat;
  display: inline-block;
  cursor: pointer;
  position: absolute;
  right: 55px;
  top: 16px;
  z-index: 0;
}
.sc-bdvvtL {
  height: 90vh !important;
  // overflow-y: scroll !important;
}
.sc-dlVxhl {
  overflow: scroll !important;
}
.e-more-users {
  background-image: url(../../assets/images/users/view.svg);
  width: 15px;
  height: 15px;
  background-size: 15px;
  background-repeat: no-repeat;
  display: inline-block;
  cursor: pointer;
  position: absolute;
  right: 55px;
  top: 16px;
  z-index: 0;
}

.e-status-active {
  color: #9c1fe9;
}
.e-status-inactive {
  color: #7d7688;
}
.e-booking-time {
  font-size: 13px;
}
.e-result-won {
  color: $white;
  background: #0e934b;
  border-radius: 100px;
  width: max-content;
  padding: 2px 10px;
  font-size: 12px;
}
.e-result-lost {
  color: $white;
  background: #b02323;
  border-radius: 100px;
  width: max-content;
  padding: 2px 10px;
  font-size: 12px;
}
.e-result-wait {
  color: $white;
  background: #f2994a;
  border-radius: 100px;
  width: max-content;
  padding: 2px 10px;
  font-size: 12px;
}
.e-negative {
  color: #b02323;
}
.e-positive {
  color: #0e934b;
}
.e-icon {
  background-image: url(../../assets/images/dataFormating/tooltip.svg);
  background-size: 16px;
  background-repeat: no-repeat;
  display: inline-block;
  cursor: pointer;
}
.e-booking-table {
  .rdt_TableBody .sc-jrQzAO.fhCFWO.rdt_TableRow,
  .dQlrRC {
    padding: 16px 0 16px !important;
  }
}
.sc-dlVxhl {
  overflow-x: auto;
  &::-webkit-scrollbar {
    // border-radius: 10px;
    height: 8px !important;
    width: 0px;
  }
  &::-webkit-scrollbar-thumb {
    background: #e5c0fc;
    padding: 10px;
    // border-radius: 10px;
  }
}
#pagination-last-page,
#pagination-first-page {
  opacity: 0.2;
}
.e-card {
  width: 138px;
  background: #ffffff;
  box-shadow: 8px 16px 56px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  position: absolute;
  top: 33px;
  right: 34px;
  z-index: 1;
  padding: 20px 15px;
  line-height: 25px;
  span {
    margin-bottom: 12px;
    @extend %font14;
    &:hover {
      opacity: 0.7;
    }
    img {
      position: relative;
      top: -2px;
    }
  }
}
.e-card-transaction {
  width: 150px;
  background: #ffffff;
  box-shadow: 8px 16px 56px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  position: absolute;
  top: 33px;
  right: 34px;
  z-index: 1;
  padding: 13px 15px;
  line-height: 25px;
  height: 50px;
  span {
    margin-bottom: 12px;
    @extend %font14;
    &:hover {
      opacity: 0.7;
    }
    img {
      position: relative;
      top: -2px;
    }
  }
}
.e-card-user {
  width: 145px;
  background: #ffffff;
  box-shadow: 8px 16px 56px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  position: absolute;
  top: 33px;
  right: 34px;
  z-index: 1;
  padding: 13px 15px;
  line-height: 35px;
  height: 90px;
  span {
    margin-bottom: 12px;
    @extend %font14;
    &:hover {
      opacity: 0.7;
    }
    img {
      position: relative;
      top: -2px;
    }
  }
}
/*-------------user table------------*/
.e-name-link {
  a {
    color: #130727 !important;
    &:hover {
      text-decoration: underline;
      color: $primary_color !important;
    }
  }
}
/*--------Scrip table--------------*/
.e-scrip-table {
  .rdt_TableHeadRow {
    padding-left: 15px !important;
  }
  .rdt_TableBody {
    cursor: pointer;
    // padding-left: 30px;
    .sc-jrQzAO.fhCFWO.rdt_TableRow:nth-child(-n + 5) {
      background: rgba(14, 147, 75, 0.1) !important;
    }
    .sc-jrQzAO.fhCFWO.rdt_TableRow:nth-last-child(-n + 5) {
      background: rgba(176, 35, 35, 0.1) !important;
    }
    .sc-jrQzAO.fhCFWO.rdt_TableRow:nth-of-type(odd) {
      background: unset;
    }
    .rdt_TableRow {
      padding-left: 18px !important;
    }
  }
}
.e-race-scrip-table {
  .rdt_TableHeadRow {
    padding-left: 15px !important;
  }
  .rdt_TableRow {
    padding-left: 18px !important;
  }
}

/*-------Blocked users table-------*/
.e-action-primary {
  color: $primary_color;
  font-weight: 700;
}
.e-blocked-users {
  .rdt_TableRow {
    padding-left: 18px !important;
  }
  .rdt_TableHeadRow {
    padding-left: 15px !important;
  }
}

/*-------------user detail transaction table-------*/
.e-credited {
  color: #0e934b;
  font-weight: 400;
}
.e-debited {
  color: #b02323;
  font-weight: 400;
}
.sc-bYoBSM {
  font-size: 13px !important;
  color: #0000008a;
}
.e-pagination {
  .sc-kLwhqv,
  .emVwZU,
  .sc-kLwhqv,
  .izjbJU {
    display: none !important;
  }
}

.pJaxb:hover:not(:disabled) {
  background-color: transparent;
}
