 /*
 *   File : transactionDetail.module.scss
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Trnsaction detail style
 *   Integrations :
 *   Version : v1.0
 */

@import "./../config.scss";

.e_transaction_detail {
  .e_modal_content{
    background-color: $white;
    width: 696px;
    // height: 344px;
    border-radius: 8px;
    h6 {
      font-weight: 700;
      font-size: 14px;
      line-height: 130%;
      color: #010101;
    }
    .e_badge {
      @extend %font14;
    }
   
    .e_details {
      h6 {
        font-weight: 600;
        font-size: 14px;
        line-height: 28px;
        color: #010101;
      }
      p {
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #010101;
      }
    }
  }
}
