 /*
 *   File : privacy.module.scss
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Privacy modal style
 *   Integrations :
 *   Version : v1.0
 */

 @import "./../config.scss";
 .e_privacy{
   .e_modal_dialog {
     max-width: 800px !important;
   }
   h6 {
     font-weight: 700;
     font-size: 16px;
     color: $grey;
   }
   p {
     font-weight: 400;
     font-size: 14px;
     color: $grey;
   }
 }