/*
 *   File : download.module.scss
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Download Button state
 *   Integrations :
 *   Version : v1.0
 *   Created : 09-12-2021
 */

@import "./../config.scss";

.e_download_btn {
  opacity: 0.4;
  pointer-events: none;
  border: 1px solid transparent;
  background-color: transparent;
  padding: 4px 12px !important;
  // background-image: url(../../assets/images/cta/download-btn.svg);
  // background-position: right center;
  // background-repeat: no-repeat;
  span {
    @extend %font14;
    font-weight: 300 !important;
    color: $primary_color;
    padding-right: 10px;
  }
  img {
    opacity: 0.4;
    pointer-events: none;
  }
}
.e_download_btn_active {
  background: $primary_color;
  border: 1px solid $primary_color;
  padding: 4px 12px 4px 12px !important;
  border-radius: 4px;
  // background-image: url(../../assets/images/cta/download-btn-active.svg);
  // background-position: 96% center;
  // background-repeat: no-repeat;

  span {
    @extend %font14;
    font-weight: 300 !important;
    color: $white;
    padding-right: 13px;
  }
  img {
    opacity: 1;
  }
  &:hover {
    // background-color: $white;
    // border: 1px solid $primary_color;
    background: $secondary_color;
    border: 1px solid $secondary_color;
  }
}
