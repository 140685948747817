/*
 *   File : otheMenu.module.scss
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Other Menu Style
 *   Integrations :
 *   Version : v1.0
 */

@import "./../config.scss";

.e_notification_menu {
  .dropdown {
    position: relative;
  }
  .e_notification_div {
    position: absolute;
    background-color: #fff;
    min-width: 320px;
    height: max-content;
    box-shadow: 2px 2px 48px rgba(0, 0, 0, 0.08);
    z-index: 3;
    right: 47px;
    top: 3px;
    padding: 15px 15px 0;
    text-align: left !important;
    .e_title {
      font-weight: 600;
      @extend %font16;
      line-height: 20px;
      letter-spacing: 0.25px;
      color: $black;
    }
    .e_span_border {
      border-top: 1px solid $notification_boder;
      display: block;
    }
    .e_sub_title {
      font-weight: 400;
      @extend %font12;
      line-height: 15px;
      text-transform: uppercase;
      color: $secondary_grey;
      text-align: left;
    }
    .e_notification_title {
      font-weight: 600;
      @extend %font16;
      line-height: 20px;
      color: $primary_color;
      padding-top: 10px;
      &:hover {
        cursor: pointer;
      }
    }
    .e_notification_content {
      font-weight: 400;
      @extend %font14;
      line-height: 22px;
      color: $secondary_grey;
    }
    .e_notification_old_title {
      font-weight: 600;
      @extend %font16;
      line-height: 20px;
      color: $secondary_grey;
      padding-top: 10px;
      &:hover {
        cursor: pointer;
      }
    }
  }
}
