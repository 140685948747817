/*
 *   File : addScrip.module.scss
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Add scrip modal style
 *   Integrations :
 *   Version : v1.0
 */

@import "./../config.scss";
.e_add_scrip {
  .e_modal_dialog {
    max-width: 900px !important;
  }
  h6 {
    font-weight: 700;
    font-size: 16px;
    color: $grey;
  }
  p {
    font-weight: 400;
    font-size: 14px;
    color: $grey;
  }
  .e_wrapper {
    background: #f7f7f7;
    box-shadow: 8px 16px 56px rgba(0, 0, 0, 0.04);
    border-radius: 8px;
    padding: 32px;
    height: 460px;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      // border-radius: 10px;
      height: 10px !important;
      width: 8px;
    }
    &::-webkit-scrollbar-thumb {
      background: #dcdbdb;
      // border-radius: 10px;
    }
  }
  .e_search_submit {
    background-image: url(../../assets/images/cta/enterarrow.png);
    width: 45px;
    height: 36px;
    display: inline-block;
    position: absolute;
    right: 0px;
    top: 0px;
    background-size: 16px;
    background-repeat: no-repeat;
    background-position: 50%;
    background-color: #9c1fe9;
    border-top-right-radius: 4px;
    cursor: pointer;
    transition: ease-in-out 0.3s;
    border-bottom-right-radius: 4px;
  }
  .e_search_close {
    background-image: url(../../assets/images/cta/close-black.png);
    background-repeat: no-repeat;
    width: 12px;
    height: 12px;
    background-size: contain;
    border: none !important;
    outline: none !important;
    background-color: transparent;
    top: 12px;
    opacity: 0.5;
    // left: 75%;
    right: 50px;
    position: absolute;
    &:hover {
      opacity: 1;
    }
  }
}
