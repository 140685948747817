/*
 *   File : editor.module.scss
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Editor style
 *   Integrations :
 *   Version : v1.0
 */

@import "./../config.scss";

/* wysiwig editor */
.ck-rounded-corners .ck.ck-editor__main > .ck-editor__editable,
.ck.ck-editor__main > .ck-editor__editable.ck-rounded-corners {
  padding-bottom: 300px;

  border-radius: 0px 0px 80px 8px;
  background-color: $white;
  margin-top: 20px;
  // background-color: $white;
  p {
    color: $black !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 25px;
    margin-bottom: 0;
  }
  p:nth-of-type(1) {
    margin-bottom: 20px;
  }
  h6 {
    font-weight: 600 !important;
    font-size: 14px !important;
  }
}
.ck-editor__editable:not(.ck-editor__nested-editable).ck-focused {
  border: none !important;
  box-shadow: none !important;
}
.ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar,
.ck.ck-editor__top .ck-sticky-panel .ck-toolbar.ck-rounded-corners {
  border: none;
  padding: 12px 12px 12px 0;
  background-color: $white !important;
  border-bottom: 1.6px solid #bdbdbd !important;
}
.ck.ck-dropdown {
  &[aria-labelledby="ck-editor__aria-label_e74d295f4749ee2c9e4e0304f597225aa"] {
    display: none !important;
  }
}
.ck .ck-button {
  &[aria-disabled="true"] {
    display: none !important;
  }
}
.ck-toolbar__items {
  .ck-dropdown:nth-of-type(1) {
    display: none !important;
  }
  .ck-dropdown:nth-of-type(3) {
    display: none !important;
  }
  .ck-dropdown:nth-of-type(2) {
    display: none !important;
  }
}
.ck-sticky-panel__content {
  z-index: 2 !important;
}
.ck-toolbar {
  &[aria-label="Dropdown toolbar"] {
    border-radius: 0px !important;
  }
}
.ck.ck-toolbar .ck.ck-toolbar__separator {
  display: none !important;
}
.ck-file-dialog-button {
  // display: none!important;
}
.ck .ck-button[aria-labelledby="ck-editor__aria-label_eca6d855d9e456b2b02e398f738ab9740"] {
  // display: none!important;
}
.ck .ck-button[aria-labelledby="ck-editor__aria-label_e26a6ab70fcce2fc537813a2e58106fc7"] {
  // display: none!important;
}
.ck.ck-editor__main > .ck-editor__editable:not(.ck-focused) {
  border-color: none !important;
}
.ck.ck-editor__main > .ck-editor__editable {
  padding: 0 100px 0 28px !important;
  border-bottom: 1.6px solid #bdbdbd !important;
  height: 680px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    // border-radius: 10px;
    height: 10px !important;
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background: #dcdbdb;
    height: 10px !important;
    // border-radius: 10px;
  }
  &::-webkit-scrollbar-track {
    // height: 10px !important;
  }
}
.ck.ck-editor__editable_inline {
  border: none !important;
}
.ck .ck-button[aria-labelledby="ck-editor__aria-label_edd9fb7b17c77d13ecab5376a0a4cda51"] {
  display: none !important;
}
.ck.ck-toolbar > .ck-toolbar__items > :not(.ck-toolbar__line-break) {
  margin-right: 30px !important;
}

/*--------------------------------*/
.e-accordion-editor {
  .ck.ck-editor {
    border: 1.5px solid #7d7688!important;
    border-radius: 8px ;
    padding: 0px 10px!important;
  }

  .ck.ck-editor__main > .ck-editor__editable {
    padding: 0 10px !important;
    border-bottom: none !important;
    height: 200px;
  }
  .ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar,
  .ck.ck-editor__top .ck-sticky-panel .ck-toolbar.ck-rounded-corners {
    border: none;
    background-color: $white !important;
    border-bottom: 1.6px solid #bdbdbd !important;
  }
}
