@charset "utf-8";
/* ==============================
   Small desktop
   ============================== */
@media only screen and (max-width: 1600px) {
}

@media only screen and (max-width: 1440px) {
   .e-download-btn {
      padding-left: 4px !important;
   }
}

@media only screen and (max-width: 1200px) {
}

@media only screen and (max-width: 1199px) {
   /*Header*/
   .e-header-desktop {
      display: none;
   }
   .e-header-mobile {
      display: block;
   }
}

/* ==============================
   Tablet landskape
   ============================== */
@media only screen and (max-width: 1024px) {
}

@media only screen and (max-width: 992px) {
}

/* ==============================
   Tablet Portrait
   ============================== */
@media only screen and (max-width: 768px) {
   .e-content-body {
      margin-left: 10px;
      margin-top: 15%;
   }
   #header {
      left: 30px;
   }
   .e-responsive-align {
      text-align: start !important;
   }
   .e-ssp-tabs {
      width: 680px;
      overflow-x: scroll;
   }
}

/* ==============================
   Phone
   ============================== */
@media only screen and (max-width: 575px) {
}

@media only screen and (max-width: 380px) {
}

/* ==============================
   Small Phone
   ============================== */
@media only screen and (max-width: 319px) {
}
