/*
 *   File : profileKyc.module.scss
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : profileKycl.module.scss
 *   Integrations :
 *   Version : v1.0
 */

@import "./../config.scss";

.e_progress_track {
  height: 4px;
  background-color: #e0e0e0;
  width: 100%;
  border-radius: 2px;
  .e_progress_bar {
    height: 4px;
    background-color: $primary_color;
    border-radius: 2px;
    width: 0;
    -webkit-transition: width 0.8s;
  }
}
