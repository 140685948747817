/*
 *   File : sidebar.module.scss
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Sidebar style
 *   Integrations :
 *   Version : v1.0
 */

@import "./../config.scss";
.e_sidebar {
  height: 100vh;
  background: $white;
  overflow-y: scroll;
  width: 256px;
  position: fixed;
  left: 0;
  top: 0;
  animation: fadeIn 0.5s;
  &::-webkit-scrollbar {
    display: none !important;
  }
  .e_sidebar_list_wrap {
    .e_logo_wrapper {
      padding: 30px 30px 0px 30px;
      .e_logo {
        height: 32px;
        width: 198px;
      }
    }
    .e_sidebar_item {
      padding: 12px 0px 12px 50px;
      img {
        top: -1px;
      }
      a {
        text-decoration: none !important;
      }
      span {
        padding-left: 20px;
        font-weight: 500;
        @extend %font14;
        line-height: 17px;
        color: $dark_grey;
      }
    }

    .e_sidebar_item_active {
      padding: 14px 0px 12px 30px !important;
      background-color: $primary_color;
      padding: 8px;
      border-radius: 4px;
      width: 220px;
      margin-left: 20px;
      @extend %font14;
      letter-spacing: -0.02em;
      img {
        position: relative;
        left: 0px;
        // width: 24px;
        // height: 24px;
      }
      a {
        text-decoration: none !important;
        width: 188px;
        height: 40px;
        color: $white;
        padding: 9px 0px;
      }
      span {
        margin-left: 20px;
      }
    }
  }
}
.e_sidebar_collapsed {
  // animation: fadeOut 0.1s;
  // animation-fill-mode: forwards;
  height: 100vh;
  width: 50px;
  background: $white;
  position: fixed;
  left: 0;
  top: 0;
  .e_collapse_logo {
    padding: 10px 0px 10px 17px;
    .e_logo {
      height: 32px;
    }
  }
  .e_sidebar_collapse_item {
    img {
      padding: 10px 0px 10px 17px;
    }
  }
  .e_sidebar_item_collapse_active {
    background-color: $primary_color;
    img {
      padding: 10px 0px 10px 17px;
    }
  }
}
a {
  text-decoration: none !important;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
    width: 256px;
  }
  25% {
    opacity: 0.3;
    width: 256px;
  }
  50% {
    opacity: 0;
    width: 256px;
  }
  75% {
    opacity: 0;
    width: 256px;
  }
  100% {
    opacity: 0;
    width: 0;
    display: none;
  }
}

@keyframes fadeOutList {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 0;
    display: none;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    width: 0;
  }
  25% {
    opacity: 0.3;
    width: 256px;
  }
  50% {
    opacity: 0.5;
    width: 256px;
  }
  75% {
    opacity: 0.7;
    width: 256px;
  }
  100% {
    opacity: 1;
    width: 256px;
  }
}
@keyframes fadeOutItem {
  0% {
    padding: 20px 0px 18px 50px;
  }
  25% {
    padding: 20px 0px 18px 50px;
  }
  50% {
    padding: 20px 0px 18px 50px;
  }
  75% {
    padding: 20px 0px 18px 50px;
  }
  100% {
    padding: 0;
  }
}
@keyframes hideImage {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 0.7;
  }
  50% {
    opacity: 0.5;
  }
  75% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
    display: none;
    padding: 0;
  }
}
