/*
 *   File : badge.module.scss
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Badge style
 *   Integrations :
 *   Version : v1.0
 */

@import "./../config.scss";

.e_success{
	border-radius: 20px;
	font-weight: 600;
	@extend %font12;
	line-height: 14px;
	text-align: center;
	background: #0E934B;
	border-radius: 100px;
	padding: 2px 16px 3px;
	color: $white;
	text-transform: capitalize;
	width: 80px;
}
.e_fail  {
	border-radius: 20px;
	font-weight: 600;
	@extend %font12;
	line-height: 14px;
	text-align: center;
	background: $badge_fail;
	border-radius: 100px;
	padding: 2px 24px 3px;
	color: $white;
	text-transform: capitalize;
	width: 80px;
}
.e_wait{
	border-radius: 20px;
	font-weight: 600;
	@extend %font12;
	line-height: 14px;
	text-align: center;
	border-radius: 100px;
	padding: 2px 24px 3px;
	color: $white;
	text-transform: capitalize;
	width: 80px;
  background: #f2994a; 
}