/*
 *   File : searchInput.module.scss
 *   Author : https://evoqueinnovativelab.com
 *   Description : InputBox style
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 15-11-2021
 */

@import "./../config.scss";

.e_search_input_box {
  background: #fff;
  border: transparent;
  box-sizing: border-box;
  border-radius: 4px;
  width: 100%;
  display: inline-block;
  padding: 8px 44px 8px 44px !important;
  background-image: url(../../assets/images/cta/search.svg);
  background-size: 14px;
  background-position: 20px 11px;
  background-repeat: no-repeat;
  font-weight: 500;
  @extend %font14;
  background-color: $search_bg !important;
  color: $black;
  &::placeholder {
    font-weight: 400;
    @extend %font14;
    line-height: 17px;
    color: $input_boder;
  }
}
.e_search_input_box:focus {
  border: none !important;
  outline: none !important;
}
.e-search-enter {
  color: $black;
  @extend %font14;
  font-weight: 500;
  padding: 11px 76px 11px 15px !important;
  background: $search_bg !important;
  border: none !important;
  &::placeholder {
    color: #000000;
    opacity: 0.5;
    line-height: 18px;
    font-weight: 500 !important;
  }
}
.e_search_submit {
  background-image: url(../../assets/images/cta/enterarrow.png);
  width: 45px;
  height: 36px;
  display: inline-block;
  position: absolute;
  right: 13px;
  top: 0px;
  background-size: 16px;
  background-repeat: no-repeat;
  background-position: 50%;
  background-color: #9c1fe9;
  border-top-right-radius: 4px;
  cursor: pointer;
  transition: ease-in-out 0.3s;
  border-bottom-right-radius: 4px;
  // left: 83%;
  // top: -46%;
}
.e_search_close {
  background-image: url(../../assets/images/cta/close-black.png);
  background-repeat: no-repeat;
  width: 12px;
  height: 12px;
  background-size: contain;
  border: none !important;
  outline: none !important;
  background-color: transparent;
  top: 12px;
  opacity: 0.5;
  // left: 75%;
  right: 65px;
  position: absolute;
  &:hover {
    opacity: 1;
  }
}
