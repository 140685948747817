/*
 *   File : tab.module.scss
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Tab style
 *   Integrations :
 *   Version : v1.0
 */

@import './../config.scss';

.e_tab{
	@extend %font14;
	font-weight: 500;
	line-height: 120%;
	color: #1F1136;
	transition: ease 0.7s;
	position: relative;
	background-color: $white;
	padding: 12px 30px;
	border-radius: 4px;
  border: 1px solid #F0F0F0;
	&:hover{
		cursor: pointer;
		background-color: $primary_color;
		color: $white;
		opacity: 0.8;
		transition: ease 0.7s;
	}
}
.e_active_tab{
	@extend .e_tab;
	transition: ease 0.3s;
	opacity: 1;
	background-color: $primary_color;
  border: 1px solid $primary_color;
	color: $white;
	border-radius: 4px;
  font-weight: 500;
  @extend %font14;
  padding: 12px 30px;
}