/*
 *   File : emptyNote.module.scss
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : emptyNote.module.scss
 *   Integrations :
 *   Version : v1.0
 */

@import "./../config.scss";

.e_empty_data {
  text-align: center;
  margin-top: 20px;
  font-weight: 600;
  @extend %font16;
  line-height: 120%;
}
.e_empty_img {
  margin: 0 auto;
  display: block;
  width: 400px;
}
