/*
 *   File : profileKyc.module.scss
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : profileKycl.module.scss
 *   Integrations :
 *   Version : v1.0
 */

@import "./../config.scss";

.e_profileKyc_card {
  background-color: $white;
  box-shadow: 8px 16px 56px rgba(0, 0, 0, 0.04);
  border-radius: 8px !important;
  padding: 24px 35px 24px 35px !important;
  transition: box-shadow 0.3s;
  z-index: 0;
  border: none !important;
  outline: none;
  height: 100%;
  margin-bottom: 25px;

  // z-index: -2;
  h5 {
    @extend %heading;
    @extend %font14;
    font-weight: 700 !important;
    color: $grey;
  }
  .e_verified {
    margin-left: 22px;
    font-weight: 500;
    @extend %font12;
    line-height: 15px;
  }
  .e_edit_icon {
    right: 20px;
    top: 25px;

    &:hover {
      cursor: pointer;
    }
  }
  .e_content_wrap {
    background: $light_blue;
    border-radius: 8px;
    padding: 20px 15px 0px;
    height: 100% !important;
  }
  .e_content_title {
    font-weight: 600 !important;
    @extend %font14;
    line-height: 17px;
    color: $input_boder;
  }
  .e_content {
    font-weight: 500 !important;
    @extend %font14;
    color: $grey;
  }
  .e_ellipse {
    overflow-y: scroll;
    // text-overflow: ellipsis;
    // display: -webkit-box;
    // -webkit-line-clamp: 1;
    // -webkit-box-orient: vertical;
    height: 35px;
  }
  .e_verify {
    border: none;
    color: $white;
    padding: 12px 30px;
    @extend %font16;
    font-weight: 500;
    border-radius: 0px;
    &:after {
      border-radius: 0px;
    }
    &:hover:after {
      border-radius: 0px;
    }
  }
}
