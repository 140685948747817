/*
 *   File : toast.module.scss
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Toast.module.scss
 *   Integrations :
 *   Version : v1.0
 */

@import "./../config.scss";

.e_toast {
  position: fixed;
  bottom: 100px;
  right: 30px;
  width: 270px !important;
  padding: 15px 15px 15px 40px;
  background-color: #fff;
  border: 1px solid #f1f1f1;
  border-radius: 5px;
  box-shadow: 3px 4px 6px 1px rgba(0, 0, 0, 0.06);
  @extend %font14;
  background-size: 18px;
  background-position: 15px;
  background-repeat: no-repeat;

  z-index: 1099;
}
.e_success {
  @extend .e_toast;
  background-image: url("../../assets/images/note/check.png");
  border-color: $toast_success_border;
  background-color: $toast_success_bg;
  color: $toast_success_color;
  overflow: hidden;
  text-overflow: ellipsis;
  z-index: 1099;
}
.e_fail {
  @extend .e_toast;
  background-image: url("../../assets/images/note/cancel.svg");
  border-color: red;
  background-color: $toast_fail_bg;
  overflow: hidden;
  text-overflow: ellipsis;
}
.e_warning_card {
  width: 450px;
  position: fixed !important;
  bottom: 100px;
  right: 30px !important;
  background-image: url(../../assets/images/note/caasion.png);
  background-repeat: no-repeat;
  background-size: 25px;
  background-position: 5% 13%;
  border-left: 4px solid #ffc300 !important;
  box-shadow: 0px 24px 64px rgba(0, 0, 0, 0.04);
  z-index: 2;
  p {
    padding-left: 60px;
    font-weight: 500;
    color: $black;
    font-size: 14px;
  }
  h6 {
    font-weight: 800;
    color: $secondary_color;
    padding-left: 60px;
  }
  img {
    width: 10px !important;
    height: 10px !important;
    min-width: 10px !important;
    &:hover {
      cursor: pointer;
      opacity: 0.7;
    }
  }
}
