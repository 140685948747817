/*
 *   File : scripList.module.scss
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : profileKycl.module.scss
 *   Integrations :
 *   Version : v1.0
 */

@import "./../config.scss";

.e_scrip_list {
  background: #ffffff;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.48);
  border-radius: 8px !important;
  padding: 10px 8px !important;
  transition: box-shadow 0.3s;
  z-index: 0;
  border: 0.5px solid #f3f3f3 !important;
  outline: none;
  h5 {
    font-weight: 600 !important;
    color: #1f1136;
    font-size: 12px;
  }
  .e_verified {
    margin-left: 10px;
    font-weight: 700;
    @extend %font10;
    line-height: 15px;
    color: $primary_color;
  }
  img {
    &:hover {
      cursor: pointer;
      opacity: 0.7;
    }
  }
}

.e_scrip_list_type {
  background: #ffffff;
  border-radius: 8px !important;
  padding: 10px 8px !important;
  transition: box-shadow 0.3s;
  z-index: 0;
  border: none !important;
  outline: none;
  h5 {
    font-weight: 600 !important;
    color: #1f1136;
    font-size: 12px;
  }
  .e_remove {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.01em;
    color: #fa6363;
    text-transform: uppercase;
    &:hover {
      cursor: pointer;
      opacity: 0.7;
    }
  }
  .e_add {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.01em;
    color: $primary_color;
    &:hover {
      cursor: pointer;
      opacity: 0.7;
    }
  }
}
